export function DrawArrowPreview(arrowPoints, pointerPosition, borderColor, dash) {
  const arrowPreview = {
    config: {
      points: [...arrowPoints, pointerPosition.x, pointerPosition.y],
      pointerLength: 10,
      pointerWidth: 10,
      pointerColor: borderColor,
      fill: borderColor,
      stroke: borderColor,
      strokeWidth: 1,
      draggable: false,
      strokeScaleEnabled: false,
      dash: dash
    }
  }

  return arrowPreview
}
