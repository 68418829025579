<script setup>
import { ref, onMounted } from 'vue'

const props = defineProps({
  visible: Boolean,
  textToEdit: Object,
  isNameOfDraw: Boolean,
  actualName: String
})
const textInput = ref('')
const emit = defineEmits(['cancel', 'submit', 'update', 'submitImage'])

function submitText(text) {
  if(text === '') {
    emit('cancel')
    return
  }

  if(props.isNameOfDraw) {
    emit('submitImage', text)
    return
  }

  if(props.textToEdit) {
    emit('update', text)
  } else {
    emit('submit', text)
  }
  textInput.value = ''
}

function cancel() {
  emit('cancel')
  textInput.value = ''
}

const placeholder = () => {
  if(props.isNameOfDraw) {
    return 'Inserisci il nome del disegno'
  } else {
    return 'Inserisci il testo'
  }
}

onMounted(() => {
  if(props.isNameOfDraw) {
    textInput.value = props.actualName
    return
  }
  if(props.textToEdit) {
    textInput.value = props.textToEdit.config.text
  }
})
</script>

<template>
<div class="text-input-overlay">
  <div class="text-input-box">
    <input
      v-model="textInput"
      @keyup.enter="submitText(textInput)"
      :placeholder="placeholder()"
    />
    <button @click="submitText(textInput)">OK</button>
    <button @click="cancel">Annulla</button>
  </div>
</div>
</template>

<style scoped>
.text-input-box {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}
.text-input-box input {
  margin-bottom: 10px;
  width: 10
}
</style>