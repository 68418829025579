export function HandleTransformEnd(shape, node) {
  if (shape) {
    if (shape.type === 'arrow') {
      const scaleX = node.scaleX()
      const scaleY = node.scaleY()

      const points = shape.config.points.map((point, index) => {
        if (index % 2 === 0) {
          return point * scaleX
        } else {
          return point * scaleY
        }
      })

      shape.config.points = points
      node.scaleX(1)
      node.scaleY(1)
      shape.config.pointerLength = 10
      shape.config.pointerWidth = 10
    } else {
      shape.config.rotation = node.rotation()
      shape.config.scaleX = node.scaleX()
      shape.config.scaleY = node.scaleY()
    }
  }
}
