export function SaveDraw(method, formData, drawId) {
  const url = method === "POST" ? '/draws' : `/draws/${drawId}`
  fetch(url, {
    method: method,
    headers: {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
    },
    body: formData
  }).then(response => {
    if (response.ok) {
      window.location.href = '/draws'
    }
  })
}
