// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import { Turbo } from "@hotwired/turbo-rails"
import TomSelect from 'tom-select';
import 'tom-select/dist/css/tom-select.bootstrap5.css';
import "bootstrap"
import {
  co2LineChart,
  eLineChart,
  h2oLineChart
} from './charts/dashboards'
import { createApp } from 'vue'
import DrawTool from '../components/DrawTool.vue'
import VueKonva from 'vue-konva';
import 'bootstrap-icons/font/bootstrap-icons.css';

//  Resolve hotwired  self entitled developers lack of contact with reality
//  https://discuss.hotwired.dev/t/event-to-know-a-turbo-stream-has-been-rendered/1554/24
const afterRenderEvent = new Event("turbo:after-stream-render");
addEventListener("turbo:before-stream-render", (event) => {
  const originalRender = event.detail.render

  event.detail.render = function (streamElement) {
    originalRender(streamElement)
    document.dispatchEvent(afterRenderEvent);
  }
})

Turbo.start()

document.addEventListener('turbo:load', () => {
  initTomSelect()
  initCharts('co2')
  initCharts('e')
  initCharts('h2o')
  if(document.getElementById('vue-app') !== null) {
    const drawData = JSON.parse(document.getElementById('vue-app').getAttribute('data-draw'))
    let app
    if(drawData) {
      app = createApp(DrawTool, {
        drawData: drawData
      })
    } else {
      app = createApp(DrawTool)
    }
    app.use(VueKonva);
    app.mount('#vue-app')
  }
})

document.addEventListener('turbo:frame-load', () => {
  initTomSelect()
})

document.addEventListener("turbo:render", function () {
  initTomSelect()
})


document.addEventListener("turbo:after-stream-render", function () {
  initTomSelect()
})

function initTomSelect() {
  let settings = {}
  Array.from(document.getElementsByClassName('tom-select')).forEach((element) => {
    element.classList.remove("tom-select");
    new TomSelect(element, settings)
  })
}

function initCharts(type) {
  let canvas = document.getElementById(`chart-${type}`)
  let detailCanvas = document.getElementById(`chart-${type}-detail`)
  switch (type) {
    case 'co2':
      if(canvas) {
        co2LineChart(canvas)
      }
      if(detailCanvas) {
        co2LineChart(detailCanvas)
      }
      break;
    case 'e':
      if(canvas) {
        eLineChart(canvas)
      }
      if(detailCanvas) {
        eLineChart(detailCanvas)
      }
      break;
    case 'h2o':
      if(canvas) {
        h2oLineChart(canvas)
      }
      if(detailCanvas) {
        h2oLineChart(detailCanvas)
      }
      break;
  }
}
