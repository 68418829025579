<script setup>
const props = defineProps({
  actualShape: String,
  actualBorder: String,
  actualColor: String
})
const continuousImage = '/draw-toolbar/continuous.svg'
const dashedImage = '/draw-toolbar/dashed.svg'
const pointImage = '/draw-toolbar/point.svg'

const classShapeSelected = (shape) => {
  return shape === props.actualShape ? 'button-selector-selected' : 'button-selector'
}

const classBorderSelected = (border) => {
  return border === props.actualBorder ? 'button-selector-selected' : 'button-selector'
}

const selectedColor = () => {
  return `/draw-toolbar/${props.actualColor}.svg`
}

</script>

<template>
  <div class="toolbar-container">
    <span class="tool-container me-1">
      <button :class="classShapeSelected('')" @click="$emit('selectShape', '')">
        <i class="bi bi-cursor"></i>
      </button>
      <button :class="classShapeSelected('circle')" @click="$emit('selectShape', 'circle')">
        <i class="bi bi-circle"></i>
      </button>
      <button :class="classShapeSelected('rectangle')" @click="$emit('selectShape', 'rectangle')">
        <i class="bi bi-square"></i>
      </button>
      <button :class="classShapeSelected('triangle')" @click="$emit('selectShape', 'triangle')">
        <i class="bi bi-triangle"></i>
      </button>
      <button :class="classShapeSelected('arrow')" @click="$emit('selectShape', 'arrow')">
        <i class="bi bi-arrow-up-right"></i>
      </button>
      <button :class="classShapeSelected('text')" @click="$emit('selectShape', 'text')">
        <i class="bi bi-fonts"></i>
      </button>
      <button :class="classShapeSelected('eraser')" @click="$emit('selectShape', 'eraser')">
        <i class="fa-solid fa-eraser"></i>
      </button>
      <!-- <button class="button-selector" @click="$emit('undoLastAction')">
        <i class="bi bi-arrow-counterclockwise"></i>
      </button> -->
    </span>
    <span class="tool-container me-1">
      <button :class="classBorderSelected('continuous')" @click="$emit('selectBorder', 'continuous')">
        <img :src=continuousImage class='button-image'/>
      </button>
      <button :class="classBorderSelected('dashed')" @click="$emit('selectBorder', 'dashed')">
        <img :src=dashedImage class='button-image'/>
      </button>
      <button :class="classBorderSelected('point')" @click="$emit('selectBorder', 'point')">
        <img :src=pointImage class='button-image'/>
      </button>
      <img :src=selectedColor() class='button-image ms-2 me-2'/>
      <select
        :selected=actualColor
        @change="$emit('selectColor', $event.target.value)"
        class="me-1"
      >
        <option value="black">Nero</option>
        <option value="red">Rosso</option>
        <option value="yellow">Giallo</option>
        <option value="green">Verde</option>
        <option value="blue">Blu</option>
      </select>
    </span>
    <button class="button-selector" @click="$emit('saveImage')">
      <i class="bi bi-floppy2"></i>
    </button>
    <button class="button-selector" @click="$emit('downloadImage')">
      <i class="bi bi-download"></i>
    </button>
  </div>
</template>

<style scoped>
.toolbar-container {
  background-color: #EEEEEE;
  border-bottom: 1px solid #000000;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  min-height: 50px;
  padding: 10px;
}
.button-selector {
  border: 1px solid #AAAAAA;
  border-radius: 4px;
  margin-right: 5px;
  padding: 5px 10px;
}
.button-selector-selected {
  border: 1px solid #AAAAAA;
  border-radius: 4px;
  margin-right: 5px;
  padding: 5px 10px;
  background-color: #888888;
}
.button-image {
  width: 18px;
  height: 16px
}
.tool-container {
  border-right: 1px solid #888888;
}
</style>
